<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <!--  <v-btn
            class="rounded-lg dialogAction-btnText text-capitalize py-5 px-7"
            dark
         color="#2C1963"
         style="width: 30%;
    font-size: 21px;
    height: 60px;"
         @click="GoToGameList()"
          v-if="this.subAdminAccess=='game'">
            <span>Go To Game</span>
          </v-btn>-->
    <!-- overview kpi's section-->
    <v-sheet
      v-if="
        !is_payout_done && $route.matched[0].path.substring(1) !== 'teacher' && !isDistrictRoute
      "
      class="stripSetupBox py-5 my-7"
      width="100%"
      color="#FFF0C9"
    >
      <div class="d-flex" style="justify-content: space-evenly">
        <v-img max-width="113" src="@/assets/stripLogo.svg"></v-img>
        <div class="align-self-center stripSetupText">
          <div class="first">
            Please complete Stripe Payout setup to start receiving donations.
          </div>
          <div class="second">
            The last step before you get donations. Please add the details,
            where would you like to receive the donations.
          </div>
        </div>
        <div class="align-self-center">
          <v-btn
            @click="setupStripePayment()"
            class="stripeSetupBtn"
            color="#2C1963"
            dark
          >
            <span> Proceed to Complete Setup </span>
          </v-btn>
        </div>
      </div>
    </v-sheet>
    <!--  <div  v-if="
          this.$route.matched[0].path.substring(1) === 'school'
        ">
      <v-card flat class="card-datatable">
      <v-card-text>
      <template>
      <tr>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Organization Name,city & state</div>
          <div style="font-weight: 700"> {{ this.schoolDetail.school_name }}</div>
         <div> <span>{{
            this.schoolDetail.city_name
          }},{{ this.schoolDetail.state_name }}</span></div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Principle video complete</div>
          <div v-if="this.schoolDetail.principal_video == false">
            <img
              src="@/assets/thingsToDo/crossimg1.png"
              width="20px"
              height="20px"
            />
          </div>
          <div v-else-if="this.schoolDetail.principal_video == true">
            <img
              src="@/assets/thingsToDo/greenimage.png"
              width="20px"
              height="20px"
            />
          </div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">School incentives complete</div>
          <div v-if="this.schoolDetail.incentives_status == false" ><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else-if="this.schoolDetail.incentives_status == true "  > <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">What school is raising money for</div>
          <div v-if="this.schoolDetail.raising_money_purpose == null"><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else-if="this.schoolDetail.raising_money_purpose == 'null'"><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else> <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
                
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Teacher List Complete</div>
          <div v-if="this.schoolDetail.teacher_list == false" ><img
                src="@/assets/thingsToDo/crossimg1.png"
                width="20px"
                height="20px"
              /></div>
              <div v-else > <img
                src="@/assets/thingsToDo/greenimage.png"
                width="20px"
                height="20px"
              /></div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Pre-Registration Zoom date & Time</div>
         <div style="font-weight: 700"> {{ this.schoolDetail.pre_registration_date_str
               }}</div><div>{{
                this.schoolDetail.pre_registration_start_time_str
              }}</div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Kickoff Assembly date & times</div>
          <div style="font-weight: 700"> {{ this.schoolDetail.kickoff_date_str }}</div><div>{{ this.schoolDetail.kickoff_starttime_str }}</div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Gold Ball raffle date and time</div>
          <div style="font-weight: 700"> {{ this.schoolDetail.golden_ball_date_str }}</div><div>{{
                this.schoolDetail.golden_ball_start_time_str
              }}</div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">
            Graduation Celebration AssembliesDate & times
          </div>
          <div style="font-weight: 700">{{ this.schoolDetail.graduation_date_str }}</div><div>{{ this.schoolDetail.graduation_starttime_str }}</div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">Donations Close date</div>
         <div style="font-weight: 700">{{this.schoolDetail.donation_close_date_str}}</div>
        </td>
        <td class="text-center row-item py-4">
          <div style="font-weight: 600;background-color:#ECECEC">
            WizFit Shuffle Teacher Zoom Date & Time
          </div>
         <div style="font-weight: 700">{{this.schoolDetail.teacher_training_date_str}}</div><div>{{this.schoolDetail.teacher_training_time_str}}</div>
        </td>
       </tr>
       </template>
    
      </v-card-text>
       </v-card >
    </div>-->
    <v-spacer></v-spacer>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div class="justifiy-end">
        <v-btn
          text
          class="mr-2 text-capitalize"
          style="border: 1px solid #2c1963; border-radius: 8px; margin: 6px"
          height="40"
          @click="viewPublicDonationPageHandler"
        >
          <v-icon color="#2C1963" left small>mdi-eye</v-icon>
          <span style="color: #2c1963">View public page</span>
        </v-btn>
        <v-btn
          text
          class="mr-2 text-capitalize"
          style="border: 1px solid #2c1963; border-radius: 8px; margin: 5px"
          height="40"
          @click="editPublicPageAction"
        >
          <v-icon small left color="#2C1963">mdi-pencil</v-icon>
          <span style="color: #2c1963">Edit public page</span>
        </v-btn>
        <v-btn
          style="border-radius: 8px; margin: 5px"
          color="#2C1963"
          dark
          height="40"
          class="mr-2 text-capitalize"
          @click="toggleShareFundraiserModal({ show: true })"
          v-if="this.$route.matched[0].path.substring(1) === 'teacher'"
        >
          <v-icon left small>mdi-share-variant</v-icon>
          <span class="ma-auto" style="color: white">Share public pages</span>
        </v-btn>
        <v-btn
          style="border-radius: 8px; margin: 5px"
          color="#2C1963"
          dark
          height="40"
          class="mr-2 text-capitalize"
          @click="toggleInviteViaMailModal({ show: true })"
          v-if="this.$route.matched[0].path.substring(1) === 'teacher'"
        >
          <img
            src="@/assets/thingsToDo/emailicon1.svg"
            width="20"
            height="20"
            style="margin-right: 9px"
          />

          <span class="ma-auto" style="color: white">Share Via Email</span>
        </v-btn>
        <v-btn
          style="border-radius: 8px; margin: 5px"
          color="#2C1963"
          dark
          height="40"
          class="text-capitalize"
          @click="toggleInviteViaSmsModal({ show: true })"
          v-if="this.$route.matched[0].path.substring(1) === 'teacher'"
        >
          <img src="@/assets/thingsToDo/textsms1.png" width="20" height="20" />
          <span class="ma-auto" style="color: white">Share Via Text</span>
        </v-btn>
      </div>
    </div>
    <div
      v-if="
        $vuetify.breakpoint.smAndUp &&
        this.$route.matched[0].path.substring(1) == 'school'
      "
    >
      <div class="step-accordion">
        <div style="margin-right: 66%">
          <span><img src="@/assets/thingsToDo/timeline.png" /></span>&nbsp;
          <span>Timeline</span>
        </div>
        <div>
          <v-icon
            @click="setFlag3()"
            v-if="!this.tableFlag3"
            style="
              cursor: pointer;
              margin-left: -14%;
              font-size: 41px;
              color: white;
            "
            >mdi-chevron-up</v-icon
          >
          <v-icon
            @click="setFlagCollpase3()"
            v-else-if="this.tableFlag3"
            style="
              cursor: pointer;
              margin-left: -14%;
              font-size: 41px;
              color: white;
            "
            >mdi-chevron-down</v-icon
          >
        </div>
      </div>

      <div v-if="this.tableFlag3" class="step-discription">
        <br />
        <div >
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.pre_registration_date3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1" style="font-size: 16px">
              {{ this.pre_registration_start_time_str }} -
              {{ this.pre_registration_end_time_str }}
            </v-col>
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
            >
              ZOOM
            </v-col>
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
            >
              Pre-Registration
            </v-col>
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.kick_off_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1" style="font-size: 16px">
              {{ this.kick_off_start_time_str }}-{{
                this.kick_off_end_time_str
              }}
            </v-col>
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >IN-PERSON</v-col
            >
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
            >
              Kick Off Assembly
              <div>
                <v-icon
                  @click="settimelineFlag()"
                  v-if="!this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 86%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase()"
                  v-else-if="this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 86%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="center">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_kickoff_assemblies"
              ></div>
            </v-row>
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_eblast_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1"> </v-col>
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >EMAIL BLAST</v-col
            >
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Platinum Ball</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_giveaway_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1" style="font-size: 16px">
              {{ this.gold_ball_start_time_str }} -
              {{ this.gold_ball_end_time_str }}</v-col
            >
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >ZOOM</v-col
            >
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Platinum Ball Giveway</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_eblast_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1"> </v-col>
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >EMAIL BLAST</v-col
            >
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Graduation Celebration</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_celebration_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1" style="font-size: 16px">
              {{ this.graduation_start_time_str }} -
              {{ this.graduation_end_time_str }}</v-col
            >
            <v-col
              cols="2"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >IN-PERSON</v-col
            >
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Graduation Celebration Assembly
              <div>
                <v-icon
                  @click="settimelineFlag1()"
                  v-if="!this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase1()"
                  v-else-if="this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag1">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="left">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_graduation_assemblies"
              ></div>
            </v-row>
            <br />
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="3"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.end_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1" style="font-size: 16px"
              >11:59 PM</v-col
            >
            <v-col cols="2" class="px-2 timeline1"> </v-col>
            <v-col
              cols="4"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Donation Close</v-col
            >
          </v-row>
        </div>
    
        <hr class="new1" />
      </div>
    </div>
    <div
      v-if="
        $vuetify.breakpoint.xsOnly &&
        this.$route.matched[0].path.substring(1) == 'school'
      "
    >
      <div class="step-accordionmob">
        <div style="margin-right: 11%">
          <span
            ><img
              src="@/assets/thingsToDo/timeline.png"
              width="20px"
              height="20px" /></span
          >&nbsp;
          <span>Timeline</span>
        </div>
        <div>
          <v-icon
            @click="setFlag3()"
            v-if="!this.tableFlag3"
            style="cursor: pointer; font-size: 41px; color: white"
            >mdi-chevron-up</v-icon
          >
          <v-icon
            @click="setFlagCollpase3()"
            v-else-if="this.tableFlag3"
            style="cursor: pointer; font-size: 41px; color: white"
            >mdi-chevron-down</v-icon
          >
        </div>
      </div>

      <div v-if="this.tableFlag3" class="step-discriptionmob">
        <br />
        <div v-if="this.campaignType == 'school'">
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.pre_registration_date3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 15px">
              {{ this.pre_registration_start_time_str }} -
              {{ this.pre_registration_end_time_str }}
            </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
            >
              ZOOM
            </v-col>
            <v-col cols="12" class="px-2 timeline2" style="font-size: 19px">
              Pre-Registration
            </v-col>
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.kick_off_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 15px">
              {{ this.kick_off_start_time_str }}-{{
                this.kick_off_end_time_str
              }}
            </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
              >IN-PERSON</v-col
            >
            <v-col cols="12" class="px-2 timeline2" style="font-size: 19px">
              Kick Off Assembly
              <div>
                <v-icon
                  @click="settimelineFlag()"
                  v-if="!this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 86%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase()"
                  v-else-if="this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 57%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="center">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_kickoff_assemblies"
              ></div>
            </v-row>
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_eblast_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1"> </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
              >EMAIL BLAST</v-col
            >
            <v-col cols="12" class="px-2 timeline2" style="font-size: 19px"
              >Platinum Ball</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_giveaway_date_str_3 }}
            </v-col>
            <v-col cols="13" class="px-2 timeline1" style="font-size: 15px">
              {{ this.gold_ball_start_time_str }} -
              {{ this.gold_ball_end_time_str }}</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
              >ZOOM</v-col
            >
            <v-col cols="14" class="px-2 timeline2" style="font-size: 19px"
              >Platinum Ball Giveway</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_eblast_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1"> </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
              >EMAIL BLAST</v-col
            >
            <v-col cols="14" class="px-2 timeline2" style="font-size: 19px"
              >Graduation Celebration</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_celebration_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 15px">
              {{ this.graduation_start_time_str }} -
              {{ this.graduation_end_time_str }}</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 16px; font-weight: 500"
              >IN-PERSON</v-col
            >
            <v-col cols="14" class="px-2 timeline2" style="font-size: 19px"
              >Graduation Celebration Assembly
              <div>
                <v-icon
                  @click="settimelineFlag1()"
                  v-if="!this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase1()"
                  v-else-if="this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag1">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="left">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_graduation_assemblies"
              ></div>
            </v-row>
            <br />
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.end_date_str_3 }}
            </v-col>
            <v-col cols="13" class="px-2 timeline1" style="font-size: 15px"
              >11:59 PM</v-col
            >
            <v-col cols="2" class="px-2 timeline1"> </v-col>
            <v-col cols="14" class="px-2 timeline2" style="font-size: 19px"
              >Donation Close</v-col
            >
          </v-row>
        </div>
        <div v-if="this.campaignType == 'district'">
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.pre_registration_date3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 16px">
              {{ this.pre_registration_start_time_str }} -
              {{ this.pre_registration_end_time_str }}
            </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
            >
              ZOOM
            </v-col>
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
            >
              Pre-Registration
            </v-col>
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.kick_off_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 16px">
              {{ this.kick_off_start_time_str }}-{{ this.kick_off_end_time_str }}
            </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >IN-PERSON</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
            >
              Kick Off Assembly
              <div>
                <v-icon
                  @click="settimelineFlag()"
                  v-if="!this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 86%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase()"
                  v-else-if="this.tabletimelineFlag"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 86%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="center">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_kickoff_assemblies"
              ></div>
            </v-row>
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_eblast_date_str_3 }}
            </v-col>
            <v-col cols="3" class="px-2 timeline1"> </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >EMAIL BLAST</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Platinum Ball</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.gold_ball_giveaway_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 16px">
              {{ this.gold_ball_start_time_str }} -
              {{ this.gold_ball_end_time_str }}</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >ZOOM</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Platinum Ball Giveway</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_eblast_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1"> </v-col>
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >EMAIL BLAST</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Graduation Celebration</v-col
            >
          </v-row>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.graduation_celebration_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 16px">
              {{ this.graduation_start_time_str }} -{{ this.graduation_end_time_str }}
              </v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-size: 20px; font-weight: 600"
              >IN-PERSON</v-col
            >
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Graduation Celebration Assembly
              <div>
                <v-icon
                  @click="settimelineFlag1()"
                  v-if="!this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-up</v-icon
                >
                <v-icon
                  @click="setFlagtimelineCollpase1()"
                  v-else-if="this.tabletimelineFlag1"
                  style="
                    cursor: pointer;
                    font-size: 25px;
                    color: black;
                    margin-left: 94%;
                    margin-top: -16%;
                  "
                  >mdi-chevron-down</v-icon
                >
              </div></v-col
            >
            <br />
          </v-row>

          <div v-if="this.tabletimelineFlag1">
            <label style="margin-left: 1%; font-size: 18px; font-weight: 500"
              >Additional Assemblies</label
            >
            <v-row no-gutters justify="left">
              <br />
              <div
                style="
                  width: 100%;
                  padding: 10px;
                  border: 1px solid gray;
                  border-radius: 5px;
                "
                v-html="additional_graduation_assemblies"
              ></div>
            </v-row>
            <br />
          </div>
          <hr class="new1" />
          <v-row no-gutters justify="left">
            <v-col
              cols="12"
              class="px-2 timeline1"
              style="font-weight: 500; color: #38227a"
            >
              {{ this.end_date_str_3 }}
            </v-col>
            <v-col cols="12" class="px-2 timeline1" style="font-size: 16px"
              >11:59 PM</v-col
            >
            <v-col cols="2" class="px-2 timeline1"> </v-col>
            <v-col
              cols="12"
              class="px-2 timeline2"
              style="font-size: 20px; font-weight: 600"
              >Donation Close</v-col
            >
          </v-row>
        </div>
        <hr class="new1" />
      </div>
    </div>
    <br />
    <p style="font-size: 25px; font-family: Lato">Overview</p>

    <div>
      <div
        v-if="
          this.$route.matched[0].path.substring(1) === 'district' ||
          this.$route.matched[0].path.substring(1) === 'school'
        "
      >
        <v-row>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(211, 0, 36, 0.12)"
                    style="margin-top: 10px"
                  >
                    <!-- <v-img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"
                    ></v-img> -->
                    <img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"
                      alt=""
                      width="25"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_students_registered }}
                        <span v-if="overviewData.total_student > 0"
                          >/ {{ overviewData.total_student }}(
                          {{
                            overviewData.total_students_registered_percentage
                          }}% )</span
                        >
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Total Student Registrations
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(6, 118, 5, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/totalFund.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">${{ raisedDonations1 }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Total Funds Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(23, 166, 140, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/averageDonation.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        ${{ overviewData.average_donation }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Average Donation Amount
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            v-if="
              $route.matched[0].path.substring(1) === 'school' ||
              $route.matched[0].path.substring(1) === 'district'
            "
          >
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(23, 166, 140, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/averageDonation.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        ${{ overviewData.average_active_donation }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Average Active Fundraiser Amount
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(0, 9, 237, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/watchedVideo.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_videos_watched }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Videos Watched
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(222, 173, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/totalNumberStudent.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.active_fundraisers }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Active Fundraisers
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(231, 111, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/noofDonor.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_donor }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Donors
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(0, 133, 255, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/noofDonation.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_donation }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Donations
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2 py-3"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(157, 0, 231, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="20"
                      width="25"
                      src="@/assets/DistrictCampaign/Dashboard/noofShare.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_shares }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Shares
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
            <v-col cols="12" sm="3" v-if=" this.adminAccess=='Super Admin' ">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(231, 111, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/shareraise.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.raised_zero }}({{
                          overviewData.percent_raised_zero
                        }}%)
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                        Zero Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3"  v-if="this.adminAccess=='Super Admin' ">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(231, 111, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/zeroshare.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.zero_shares }}({{
                          overviewData.zero_shares_percentage
                        }}%)
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Zero Share
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
         
            <v-col cols="12" sm="3" v-if=" this.adminAccess=='Super Admin' ">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(231, 111, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/nosharenoraise.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.no_share_no_raise }}({{
                          overviewData.no_share_no_raise_percent
                        }}%)
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      No Share No Raise
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(211, 0, 36, 0.12)"
                    style="margin-top: 10px"
                  >
                    <!-- <v-img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"
                    ></v-img> -->
                    <img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"
                      alt=""
                      width="25"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        ${{ overviewData.school_fund_raise }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      School Funds Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(6, 118, 5, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/totalFund.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        ${{ overviewData.class_fund_raised }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Classroom Funds Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(211, 0, 36, 0.12)"
                    style="margin-top: 10px"
                  >
                    <!-- <v-img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"

                    ></v-img> -->
                    <img
                      src="@/assets/DistrictCampaign/Dashboard/student.png"
                      alt=""
                      width="25"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        ${{ overviewData.teacher_fund_raised }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Teacher Funds Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(222, 173, 0, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="25"
                      src="@/assets/DistrictCampaign/Dashboard/totalNumberStudent.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_students_registered }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Total Number of Students Registered in Class
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card class="cards py-0">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center pa-2 py-3"
                    rounded
                    height="42"
                    width="42"
                    color="rgba(157, 0, 231, 0.12)"
                    style="margin-top: 10px"
                  >
                    <img
                      height="20"
                      width="25"
                      src="@/assets/DistrictCampaign/Dashboard/noofShare.png"
                    />
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p style="margin-left: 10px">
                        {{ overviewData.total_shares }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="CardActionTitle py-0 px-0"
                      style="color: #757575"
                    >
                      Number of Shares
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
      <div>
        <v-card class="graph py-7">
          <v-card-title style="font-size: 20px; font-weight: 700">
            <v-row v-if="$vuetify.breakpoint.smAndUp">
              <v-col cols="6">
                <p>
                  <span v-if="$route.matched[0].path.substring(1) === 'teacher'"
                    >Total Classroom Fund Raised:
                    <span style="color: #067605"
                      >${{ this.overviewData.combined_total }}</span
                    ></span
                  >
                  <span v-else>
                    Total Funds Raised:
                    <span style="color: #067605">${{ raisedDonations1 }}</span>
                  </span>
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" class="text-right">
                <p>
                  Goal Reached:
                  <span style="color: #38227a"
                    >{{ overviewData.fund_raise_percentage }}%</span
                  >
                </p>
              </v-col>
            </v-row>
               <v-row v-if="$vuetify.breakpoint.xsOnly">
              <v-col cols="12" style="font-size: 15px;">
                <p>
                  <span v-if="$route.matched[0].path.substring(1) === 'teacher'"
                    >Total Classroom Fund Raised:
                    <span style="color: #067605"
                      >${{ this.overviewData.combined_total }}</span
                    ></span
                  >
                  <span v-else>
                    Total Funds Raised:
                    <span style="color: #067605">${{ raisedDonations1 }}</span>
                  </span>
                </p>
              </v-col>
              <v-col cols="12" style="font-size: 15px;">
                <p>
                  Goal Reached:
                  <span style="color: #38227a"
                    >{{ overviewData.fund_raise_percentage }}%</span
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <line-chart
              v-if="loader"
              :graph_data="overviewData.graph_data"
            ></line-chart>
          </v-card-text>
        </v-card>
      </div>

      <br />
      <div
        v-if="this.$route.matched[0].path.substring(1) === 'district'"
        class="pb-10"
      >
        <school-leaderboard></school-leaderboard>
      </div>
      <div
        v-else-if="this.$route.matched[0].path.substring(1) === 'school'"
        class="pb-10"
      >
        <class-leaderboard></class-leaderboard>
      </div>
      <div v-else class="pb-10">
        <student-leaderboard></student-leaderboard>
        <br />
        <p class="tableHeading">WizFit video showcase progress ----</p>
        <video-showcase-table v-if="loader"></video-showcase-table>
        <br />
        <p class="tableHeading">WizFit videos ----</p>
        <video-section-peteacher v-if="loader"></video-section-peteacher>
      </div>
    </div>
    <share-fundraiser-modal></share-fundraiser-modal>
    <invite-via-mail-modal></invite-via-mail-modal>
    <invite-via-sms-modal></invite-via-sms-modal>
  </div>
</template>

<script>
import {
  API_DISTRICT_PANEL_OVERVIEW,
  API_SETUP_STRIPE_CHECKOUT,
  API_SCHOOL_PANEL_OVERVIEW,
  API_GET_TEACHER_OVERVIEW,
  API_ADMIN_DONATION_OVERVIEW,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapGetters, mapActions } from "vuex";
import authToken from "@/common/authToken";
import Helper from "@/helper";
import { ROUTER_URL } from "@/constants/urls";
export default {
  name: "Dashboard",
  components: {
    SchoolLeaderboard: () =>
      import("@/components/LeaderboardDataTables/SchoolLeaderboard.vue"),
    ClassLeaderboard: () =>
      import("@/components/LeaderboardDataTables/ClasswiseLeaderboard"),
    StudentLeaderboard: () =>
      import("@/components/LeaderboardDataTables/StudentLeaderboard"),
    LineChart: () => import("@/components/Graphs/LineChart.vue"),
    ShareFundraiserModal: () =>
      import(
        "@/components/PublicDonationPages/ShareFundraiserModal/ShareFundraiserModal"
      ),
    InviteViaMailModal: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaMailModal"
      ),
    InviteViaSmsModal: () =>
      import(
        "@/components/PublicDonationPages/InviteViaMailModal/InviteViaSmsModal"
      ),
    VideoShowcaseTable: () =>
      import("../PeTeacherDashboard/VideoShowcaseTable"),
    VideoSectionPeteacher: () =>
      import(
        "../PeTeacherDashboard/VideoSectionPeteacher/VideoSectionPeteacher"
      ),
  },
  data() {
    return {
      subAdminAccess: localStorage.getItem("subadminAccess"),
      loader: false,
      loading: false,
      overviewData: {},
      graphData: {},
      is_payout_done: true,
      //schholtotalfundraised:localStorage.getItem("schoofundraised"),
      raisedDonations: "",
      raisedDonations1: "",
      schoolDetail: {},
      adminAccess: localStorage.getItem("adminAccess"),
      pre_registration_date: "",
      pre_registration_date3: "",
      pre_registration_start_time: "",
      pre_registration_end_time: "",
      pre_registration_start_time_str: "",
      gold_ball_start_time_str: "",
      gold_ball_end_time_str: "",
      pre_registration_end_time_str: "",
      kick_off_start_time_str: "",
      kick_off_end_time_str: "",
      kick_off_date_str_3: "",
      kickoff_date: "",
      kickoff_starttime: "",
      kickoff_endtime: "",
      golden_ball_date: "",
      gold_ball_eblast_date_str_3: "",
      gold_ball_giveaway_date_str_3: "",
      golden_ball_start_time: "",
      golden_ball_end_time: "",
      graduation_eblast_date_str_3: "",
      graduation_celebration_date_str_3: "",
      graduation_start_time_str: "",
      graduation_end_time_str: "",
      end_date_str_3: "",
      graduation_starttime: "",
      graduation_endtime: "",
      graduation_date: "",
      step3Flag: false,
      tableFlag3: true,
      tabletimelineFlag: false,
      tabletimelineFlag1: false,
      pre_registration_dated: "",
      kickoff_dated: "",
      golden_ball_dated: "",
      graduation_dated: "",
      gold_ball_eblstd: "",
      graduation_eblstd: "",
      preRegistrationTime: "",
      preRegistrationEndTime: "",
      kickoffstarttime: "",
      kickoffendtime: "",
      goldenballstarttime: "",
      goldenballendtime: "",
      graduationstarttime: "",
      graduationendtime: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userType() {
      return authToken.decodedToken().user_team;
    },
    layout() {
      return this.$route.meta.layout;
    },
    isPayoutDone() {
      return this.selectedCampaign.isPayoutDone;
    },
       isDistrictRoute() {
      return this.$route.path.includes('district');
    },
  },
  watch: {
    selectedCampaignData() {
      this.getOverviewData();
    },
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      toggleAddEditViewTeacherModal: "teacherManagement/toggleTeacher",
      toggleShareFundraiserModal:
        "publicDonationPage/toggleShareFundraiserModal",
      toggleUpdateDistrictModal: "districtManagement/toggleEditModal",
      toggleEditSchoolModal: "schoolManagement/toggleUpdateModal",
      toggleSchoolId: "teacherManagement/toggleSchool",
      toggleInviteViaMailModal: "publicDonationPage/toggleInviteMail",
      toggleInviteViaSmsModal: "publicDonationPage/toggleInviteSms",
    }),
    getOverviewData() {
      const successHandler = (res) => {
        console.log(res.data, "in success");
        this.is_payout_done = res.data.is_payout_done;
        this.overviewData = res.data.overview_data;
        this.raisedDonations1 = res.data.overview_data.total_fund_raised;
        this.loader = true;
        if (this.$route.matched[0].path.substring(1) === "teacher") {
          this.toggleSchoolId(res.data.overview_data.school_id);
        }
        // console.log(this.is_payout_done);
      };
      const failureHandler = (res) => {
        console.log("in failure");
        console.log(res);
      };
      let formData = {};
      if (this.layout === "CampaignDashboardLayout") {
        formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
        console.log(this.userType);
        if (this.$route.matched[0].path.substring(1) === "district") {
          formData["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else if (this.$route.matched[0].path.substring(1) === "school") {
          formData["school_id"] = this.selectedCampaignData.organizationData.id;
        } else if (this.$route.matched[0].path.substring(1) === "teacher") {
          formData["teacher_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }
      if (this.$route.matched[0].path.substring(1) === "district") {
        Axios.request_GET(
          API_DISTRICT_PANEL_OVERVIEW,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          true
        );
      } else if (this.$route.matched[0].path.substring(1) === "school") {
        Axios.request_GET(
          API_SCHOOL_PANEL_OVERVIEW,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          true
        );
      } else if (this.$route.matched[0].path.substring(1) === "teacher") {
        Axios.request_GET(
          API_GET_TEACHER_OVERVIEW,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          true
        );
      }
    },

    getOverViewDonationData() {
      console.log("inoverview");
      const successHandler = (res) => {
        this.loaded = true;
        console.log(res.data.overview_data);
        this.donations = res.data.overview_data.total_donation;
        this.raisedDonations = res.data.overview_data.total_donation_raised;
        localStorage.setItem("schoofundraised", this.raisedDonations);
        //loading graph check
        let paymentCheck = res.data.overview_data.payment_mode_breakup.value;
        let processingFeeCheck =
          res.data.overview_data.processing_fees_breakup.value;
        let donorTypeCheck = res.data.overview_data.donor_type_breakup.value;
        if (paymentCheck[0] > 0 || paymentCheck[1] > 0 || paymentCheck[2] > 0) {
          this.paymentModeLoaded = true;
        }
        if (processingFeeCheck[0] > 0 || processingFeeCheck[1] > 0) {
          this.processingFeesLoaded = true;
        }
        if (donorTypeCheck[0] > 0 || donorTypeCheck[1] > 0) {
          this.donorTypeLoaded = true;
        }
        this.payment_mode_breakup = {};

        this.payment_mode_breakup = {
          labels: res.data.overview_data.payment_mode_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.payment_mode_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };

        this.processing_fees_breakup = {
          labels: res.data.overview_data.processing_fees_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.processing_fees_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };
        this.donor_type_breakdown = {
          labels: res.data.overview_data.donor_type_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.donor_type_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
        } else {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "district") {
            formData["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
        formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
        if (this.$route.matched[0].path.substring(1) === "district") {
          formData["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else {
          formData["school_id"] = this.selectedCampaignData.organizationData.id;
        }
      }
      Axios.request_GET(
        API_ADMIN_DONATION_OVERVIEW,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    setupStripePayment() {
      let formData = {};
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      if (this.$route.query.school)
        formData["school_id"] = this.$route.query.school;
      else if (this.$route.query.district)
        formData["district_id"] = this.$route.query.district;
      if (this.$route.matched[0].path.substring(1) === "district") {
        formData["district_id"] = this.selectedCampaignData.organizationData.id;
      } else {
        formData["school_id"] = this.selectedCampaignData.organizationData.id;
      }
      const successHandler = (res) => {
        window.location.href = res.data.oauth_url;
      };
      const failureHandler = (res) => {
        console.log("in failure", res);
      };
      formData["redirect_url"] = window.location.href;
      Axios.request_GET(
        API_SETUP_STRIPE_CHECKOUT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    chekSetup() {
      console.log(this.$route.query, "setup");
      if (this.$route.query.stripe_payout_status) {
        let toastMessage = "";
        let c = "";
        if (this.$route.query.stripe_payout_status == 1) {
          toastMessage = "Payout Setup Complete";
          c = "s";
        } else {
          toastMessage = "Payout Setup Failed";
          c = "e";
        }
        this.showToast({
          message: toastMessage,
          color: c,
        });
        let query = Object.assign({}, this.$route.query);
        delete query.stripe_payout_status;
        this.$router.replace({ query });
      }
    },
    viewPublicDonationPageHandler11() {
      const PANEL = this.$route.matched[0].path.substring(1);
      const id = this.selectedCampaign.organizationData.id;
      const sub_domain = this.selectedCampaign.campaignData.sub_domain;
      const queryParam = PANEL + "=" + id;
      const campaignType = this.selectedCampaign.campaignData.campaign_type;
      if (PANEL === "teacher") {
        Helper.openPublicDonationPage(sub_domain, queryParam);
      } else if (
        PANEL === "district" ||
        (PANEL === "school" && campaignType === "school")
      ) {
        Helper.openPublicDonationPage(sub_domain);
      } else {
        Helper.openPublicDonationPage(sub_domain, queryParam);
      }
    },
    viewPublicDonationPageHandler() {
  const PANEL = this.$route.matched[0].path.substring(1);
  const sub_domain = this.selectedCampaign.campaignData.sub_domain;
  const campaignType = this.selectedCampaign.campaignData.campaign_type;
  
  if (PANEL === "teacher") {
    const id = this.selectedCampaign.organizationData.id;
    const queryParam = PANEL + "=" + id;
    Helper.openPublicDonationPage(sub_domain, queryParam);
  } else if (
    PANEL === "district" ||
    (PANEL === "school" && campaignType === "school")
  ) {
    // Open the page without passing the ID
    Helper.openPublicDonationPage(sub_domain);
  } else {
    const id = this.selectedCampaign.organizationData.id;
    const queryParam = PANEL + "=" + id;
    Helper.openPublicDonationPage(sub_domain, queryParam);
  }
},
    editPublicPageAction() {
      const PANEL = this.$route.matched[0].path.substring(1);
      if (PANEL === "teacher") {
        this.toggleAddEditViewTeacherModal({
          show: true,
          id: this.selectedCampaignData.organizationData.id,
        });
      } else if (PANEL === "school") {
        this.toggleEditSchoolModal({
          show: true,
          id: this.selectedCampaignData.organizationData.id,
        });
      } else {
        this.toggleUpdateDistrictModal({
          show: true,
          id: this.selectedCampaignData.organizationData.id,
        });
      }
    },
    getSchoolDetails() {
      this.loading = true;
      const successHandler = (res) => {
        this.schoolDetail = res.data.school_detail;
        localStorage.setItem("schoolID", res.data.school_detail.id);
        this.pre_registration_dated =
          this.schoolDetail.pre_registration_date_str_1;
        this.golden_ball_date = this.schoolDetail.golden_ball_date;
        this.golden_ball_dated = this.schoolDetail.golden_ball_date_str_1;
        this.kickoff_date = this.schoolDetail.kickoff_date;
        this.kickoff_dated = this.schoolDetail.kickoff_date_str_1;
        this.graduation_date = this.schoolDetail.graduation_date;
        this.graduation_dated = this.schoolDetail.graduation_date_str_1;
        this.kickoff_mail = this.schoolDetail.kickoff_mail;
        this.goldball_mail = this.schoolDetail.goldball_mail;
        this.graduation_mail = this.schoolDetail.graduation_mail;
        this.progress_status = this.schoolDetail.progress_status;
        this.gold_ball_eblstd = this.schoolDetail.gold_ball_eblast_str_1;
        this.graduation_eblstd = this.schoolDetail.graduation_eblast_str_1;
        this.preRegistrationTime =
          this.schoolDetail.pre_registration_start_time_str;
        this.preRegistrationEndTime =
          this.schoolDetail.pre_registration_end_time_str;
        this.kickoffstarttime = this.schoolDetail.kickoff_starttime_str;
        this.kickoffendtime = this.schoolDetail.kickoff_endtime_str;
        this.goldenballstarttime = this.schoolDetail.golden_ball_start_time_str;
        this.goldenballendtime = this.schoolDetail.golden_ball_end_time_str;
        this.graduationstarttime = this.schoolDetail.graduation_starttime_str;
        this.graduationendtime = this.schoolDetail.graduation_endtime_str;
        this.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        this.loading = false;
      };
      let formData = {};
      formData["school_id"] = this.selectedCampaignData.organizationData.id;
      formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
      /**
       * API Call for ADD District
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    GoToGameList() {
      let routerData = this.$router.resolve({
        name: ROUTER_URL.schoolPanel.children.campaignGame.name,
      });
      window.open(routerData.href, "_blank");
    },
    getCampaignDetail() {
      const self = this;
      const successHandler = function (res) {
        const data = res.data;
        // console.log(data);
        self.kickoff_date = data.campaign_detail.kick_off_date;
        self.graduation_date = data.campaign_detail.graduation_eblast_date;
        self.golden_ball_date = data.campaign_detail.gold_ball_eblast_date;
        self.gold_ball_eblast_date_str_3 =
          data.campaign_detail.gold_ball_eblast_date_str_3;
        self.graduation_eblast_date_str_3 =
          data.campaign_detail.graduation_eblast_date_str_3;
        self.end_date_str_3 = data.campaign_detail.end_date_str_3;
        self.graduation_celebration_date_str_3 =
          data.campaign_detail.graduation_celebration_date_str_3;
        self.pre_registration_date = data.campaign_detail.pre_registration_date;
        self.pre_registration_date3 =
          data.campaign_detail.pre_registration_date_str_3;
        self.kick_off_date_str_3 = data.campaign_detail.kick_off_date_str_3;
        self.gold_ball_giveaway_date_str_3 =
          data.campaign_detail.gold_ball_giveaway_date_str_3;
        self.schoolGoal = data.campaign_detail.school_default_goal;
        self.additional_kickoff_assemblies =
          data.campaign_detail.additional_kickoff_assemblies;
        self.additional_graduation_assemblies =
          data.campaign_detail.additional_graduation_assemblies;
        self.notes = data.campaign_detail.notes;
        if (data.campaign_detail.pre_registration_start_time) {
          self.pre_registration_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_start_time
          );
        }
        if (data.campaign_detail.pre_registration_end_time) {
          self.pre_registration_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_end_time
          );
        }
        if (data.campaign_detail.pre_registration_start_time_str) {
          self.pre_registration_start_time_str =
            data.campaign_detail.pre_registration_start_time_str;
        }
        if (data.campaign_detail.pre_registration_end_time_str) {
          self.pre_registration_end_time_str =
            data.campaign_detail.pre_registration_end_time_str;
        }
        if (data.campaign_detail.kick_off_start_time) {
          self.kick_off_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_start_time
          );
        }
        if (data.campaign_detail.kick_off_end_time) {
          self.kick_off_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_end_time
          );
        }
        if (data.campaign_detail.kick_off_start_time_str) {
          self.kick_off_start_time_str =
            data.campaign_detail.kick_off_start_time_str;
        }
        if (data.campaign_detail.kick_off_end_time_str) {
          self.kick_off_end_time_str =
            data.campaign_detail.kick_off_end_time_str;
        }
        if (data.campaign_detail.gold_ball_start_time) {
          self.gold_ball_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_start_time
          );
        }
        if (data.campaign_detail.gold_ball_end_time) {
          self.gold_ball_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_end_time
          );
        }
        if (data.campaign_detail.gold_ball_start_time_str) {
          self.gold_ball_start_time_str =
            data.campaign_detail.gold_ball_start_time_str;
        }
        if (data.campaign_detail.gold_ball_end_time_str) {
          self.gold_ball_end_time_str =
            data.campaign_detail.gold_ball_end_time_str;
        }
        if (data.campaign_detail.graduation_start_time_str) {
          self.graduation_start_time_str =
            data.campaign_detail.graduation_start_time_str;
        }
        if (data.campaign_detail.graduation_end_time_str) {
          self.graduation_end_time_str =
            data.campaign_detail.graduation_end_time_str;
        }
        if (data.campaign_detail.graduation_start_time) {
          self.graduation_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_start_time
          );
        }
        if (data.campaign_detail.graduation_end_time) {
          self.graduation_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_end_time
          );
        }
        self.campaignType = data.campaign_detail.campaign_type;
        self.loading = false;
      };
      const failureHandler = function (res) {
        const data = res;
        console.log(data);
      };

      let formData = {};
      formData["campaign_id"] = this.$route.query.campaign_id;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    setFlag3() {
      this.tableFlag3 = true;
    },
    setFlagCollpase3() {
      this.tableFlag3 = false;
    },
    settimelineFlag() {
      this.tabletimelineFlag = true;
    },
    setFlagtimelineCollpase() {
      this.tabletimelineFlag = false;
    },
    settimelineFlag1() {
      this.tabletimelineFlag1 = true;
    },
    setFlagtimelineCollpase1() {
      this.tabletimelineFlag1 = false;
    },
  },
  mounted() {
    // alert(this.$route.matched[0].path.substring(1))
    setTimeout(() => {
      this.getOverviewData();
      if (this.$route.matched[0].path.substring(1) === "school") {
        this.getSchoolDetails();
      }
      if (this.$route.matched[0].path.substring(1) !== "teacher") {
        this.getOverViewDonationData();
      }
      this.chekSetup();
    }, 2000);
    this.getCampaignDetail();
  },
};
</script>

<style scoped>
* {
  font-family: Lato;
}
.v-card.graph {
  background: #ffffff;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.v-card.cards.v-sheet.theme--light {
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
}
.v-list-item__title.title {
  position: relative;
  top: 15px;
}
.v-list-item__title.CardActionTitle {
  font-size: 15px;
  position: relative;
  bottom: 10px;
}
.stripSetupBox {
  border: 1.5px dashed #ff5d17 !important;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.stripeSetupBtn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 161.5%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.stripSetupText > .first {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  color: #2c1963;
}
.stripSetupText > .second {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #000000;
}
.tableHeading {
  color: #2c1963;
  font-size: 30px;
  font-family: Roboto Slab;
  font-weight: 700;
  padding-top: 70px;
}
.step-accordion {
  background: #7356e4;
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  color: white;
  height: 55px;

  font-size: 30px;
  position: relative;
  font-family: inter;
}
.step-discription {
  background: #fafafa;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 1px 1px 5px #00000036;
  margin-top: -30px;
}
.step-accordionmob {
  background: #7356e4;
  padding: 3px 17px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  color: white;
  height: 50px;
  font-size: 14px;
  position: relative;
  font-family: inter;
}
.step-discriptionmob {
  background: #fafafa;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 1px 1px 5px #00000036;
  margin-top: -30px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
